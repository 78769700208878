import React, { Component } from "react";
import {
  Grid,
  Button,
  MenuItem,
  TextField,
  FormControl,
  LinearProgress,
  InputLabel,
  Select,
  Paper,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { appConstants } from "../_constants";
import InlineDatePicker from "../Components/inlineDatePicker";
import moment from "moment";
import { commonService } from "../_services/common.service";
import CustomizedSnackbars from "../Components/Snackbar";
import { appHelpers } from "../_helpers";
// import AutoCompleteSelect from "../Components/AutocompleteSelect";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(4),
    margin: "auto",
    maxWidth: "50%",
    marginTop: "20px",
    marginBottom: "20px",
    border: "1px solid #fafafa",
  },
  formControl: {
    margin: theme.spacing(0),
    width: "100%",
  },
  title: {
    marginBottom: 0,
  },
  textField: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginTop: 0,
    width: "100%",
  },
  info: {
    fontSize: "14px",
    textAlign: "center",
    marginBottom: 0,
  },
  snackbar: {
    position: "absolute",
    top: "5%",
    left: "77%",
    width: "300px",
  },
});

class BookFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requesting: false,
      redirect: false,
      sessionId: "",
      namePrefix: "",
      FirstName: "",
      lastName: "",
      dayOfBirth: "",
      documentType: "",
      innerDocumentType: "",
      documentId: "",
      documentIssuanceCountry: "",
      documentIssueLocation: "",
      birthCountry: "",
      dateOfBirth: moment(),
      expirationYear: "",
      expirationMonth: "",
      expirationDay: "",
      expiryDate: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      passengerDetails: [],
      email: "",
      phoneNumber: "",
      formArray: [],
      formAddress: {
        ContactName: "",
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        CountryCode: "",
        PhoneNumber: "",
        Email: "",
      },
      bookingDetails: null,
      errorMessage: false,
      returnTicket: false,
      nameCharacterCount: [],
      adultInfantCount: 0,
      isInfant: false,
      countries: [],
      BirthCountry: "",
    };
  }

  componentDidMount() {
    this.fetchAllCountries();
    // const { nameCharacterCount } = this.state;

    let pathState = this.props.history.location.state;
    const formArray = pathState.formArray;
    // console.log(JSON.stringify(formArray));
    this.setState({
      passengerDetails: pathState.passengersDetails,
      sessionId: pathState.sessionId,
      //   formArray: pathState.formArray,
      returnTicket: pathState.returnTicket,
    });
    // console.log('formArray mount', formArray);
    // let isInfant = formArray.some(item => item.PassengerTypeCode === "INF"); // check if infant is present in the formArray

    let updatedFormArray = formArray.map((item) => {
      item.BirthDate = moment();
      item.Documents.map((document) => {
        document.EffectiveDate = moment();
        document.ExpireDate = moment();
        document.ExpiryDate = moment();
        return document;
      });
      return item;
    });

    // order array in order of ADT-INF then CHD
    this.reorderPassengers(updatedFormArray);
    // console.log(updatedFormArray);

    // generate initial count for each passenger form
    let nameCharacterCount = [];
    updatedFormArray.forEach((passengerForm, index) => {
      if (passengerForm.PassengerTypeCode === "INF") {
        nameCharacterCount[index] = {
          count:
            passengerForm.FirstName.length +
            passengerForm.LastName.length +
            updatedFormArray[index - 1].NamePrefix.length +
            updatedFormArray[index - 1].FirstName.length +
            updatedFormArray[index - 1].LastName.length,
          type: "ADT-INF",
        };
      } else if (passengerForm.PassengerTypeCode === "ADT") {
        if (
          updatedFormArray[index + 1] &&
          updatedFormArray[index + 1].PassengerTypeCode === "INF"
        ) {
          nameCharacterCount[index] = {
            count:
              updatedFormArray[index].NamePrefix.length +
              updatedFormArray[index].FirstName.length +
              updatedFormArray[index].LastName.length +
              updatedFormArray[index + 1].NamePrefix.length +
              updatedFormArray[index + 1].FirstName.length,
            type: "ADT-INF",
          };
        } else {
          nameCharacterCount[index] = {
            count:
              updatedFormArray[index].NamePrefix.length +
              updatedFormArray[index].FirstName.length +
              updatedFormArray[index].LastName.length,
            type: updatedFormArray[index].PassengerTypeCode,
          };
        }
      } else {
        nameCharacterCount[index] = {
          count:
            passengerForm.NamePrefix.length +
            passengerForm.FirstName.length +
            passengerForm.LastName.length,
          type: updatedFormArray[index].PassengerTypeCode,
        };
      }
    });
    // console.log({ nameCharacterCount });
    // let adultInfantCount;

    // if (isInfant) {
    //   adultInfantCount =
    //     appConstants.MAX_CHARACTER_COUNT_ADULT_INFANT -
    //     nameCharacterCount
    //       .filter(item => item.type === "ADT" || item.type === "INF")
    //       .reduce((acc, item) => {
    //         console.log(item);
    //         return acc + item.count;
    //       }, 0);
    // }

    // let adultInfantCount = nameCharacterCount
    //     .filter(item => item.type === "ADT").reduce((acc, item) => {
    //       return appConstants.MAX_CHARACTER_COUNT_ADULT_INFANT - (acc + item.count);
    //     }, 0 );

    this.setState({
      formArray: updatedFormArray,
      nameCharacterCount,
      // adultInfantCount,
      // isInfant
    });
  }

  reorderPassengers = (passengers) => {
    let adtCount = 0;
    let infCount = 0;

    for (let i = 0; i < passengers.length; i++) {
      let passengerItem = passengers[i];
      if (passengerItem.PassengerTypeCode === "ADT") {
        adtCount = adtCount + 1;
        passengerItem.sortKey = adtCount.toString();
      } else if (passengerItem.PassengerTypeCode === "INF") {
        infCount = infCount + 1;
        passengerItem.sortKey = infCount.toString() + "." + infCount.toString();
      } else {
        passengerItem.sortKey = "X." + i.toString();
      }
    }

    passengers.sort(appHelpers.compareSortKeys);
  };

  handleContactChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  // handleChange = event => {
  //     this.setState({ [event.target.name]: event.target.value });
  // };
  handleDateChange = (date, target, idx, doc, parentKey) => {
    // console.log("date", "target" ,"idx, doc, parentKey", date, target, idx, doc, parentKey);
    if (doc) {
      const newFormArray = this.state.formArray.map((item, key) => {
        // console.log({item});
        if (parentKey !== key) return item;
        let updatedItem = item.Documents.map((document, index) => {
          if (idx !== index) return document;

          return { ...document, [target]: moment(date).format("YYYY-MM-DD") };
        });
        // console.log({updatedItem})

        item.Documents = updatedItem;
        return { ...item };
      });

      // console.log({newFormArray})

      this.setState({ formArray: newFormArray });
    } else {
      const newFormArray = this.state.formArray.map((item, key) => {
        if (idx !== key) return item;
        return { ...item, [target]: moment(date).format("YYYY-MM-DD") };
      });

      this.setState({ formArray: newFormArray });
      // console.log({newFormArray})
    }
  };

  fetchAllCountries = () => {
    let payload = {
      PageSize: 100000,
      From: 0,
      Parameter: {
        CountryCode: "",
        Name: "",
      },
    };
    commonService.getAllCountries(payload).then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let result = response.ResultList;
        this.setState({
          countries: result,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        // console.log("error")
      }
    });
  };

  handleChange = (idx, doc, parentKey, passengerCode) => (evt) => {
    // const { isInfant } = this.state;
    // console.log("idx, doc, parentKey", idx, doc, parentKey, passengerCode);
    // console.log(evt.target.name);
    // console.log(evt.target.value);
    // console.log("state form array", this.state.formArray);
    if (doc) {
      const newFormArray = this.state.formArray.map((item, key) => {
        // console.log({item});
        if (parentKey !== key) return item;
        let updatedItem = item.Documents.map((document, index) => {
          if (idx !== index) return document;

          return { ...document, [evt.target.name]: evt.target.value };
        });
        // console.log({updatedItem})

        item.Documents = updatedItem;
        return { ...item };
      });

      this.setState({ formArray: newFormArray });
    } else {
      const newFormArray = this.state.formArray.map((item, key) => {
        if (idx !== key) return item;

        return { ...item, [evt.target.name]: evt.target.value };
      });

      // console.log({ newFormArray });
      // sum up character count for corresponding form
      let nameCharacterCount = this.state.nameCharacterCount;

      // nameCharacterCount = newFormArray.map(item => {
      //   return
      // })

      // calculate character count for ADT, ADT-INF and CHD
      if (passengerCode === "INF") {
        nameCharacterCount[idx] = nameCharacterCount[idx - 1] = {
          count:
            newFormArray[idx].FirstName.length +
            newFormArray[idx].LastName.length +
            newFormArray[idx - 1].NamePrefix.length +
            newFormArray[idx - 1].FirstName.length +
            newFormArray[idx - 1].LastName.length,
          type: "ADT-INF",
        };
      } else if (passengerCode === "ADT") {
        if (
          newFormArray[idx + 1] &&
          newFormArray[idx + 1].PassengerTypeCode === "INF"
        ) {
          nameCharacterCount[idx] = nameCharacterCount[idx + 1] = {
            count:
              newFormArray[idx].NamePrefix.length +
              newFormArray[idx].FirstName.length +
              newFormArray[idx].LastName.length +
              newFormArray[idx + 1].NamePrefix.length +
              newFormArray[idx + 1].FirstName.length,
            type: "ADT-INF",
          };
        } else {
          nameCharacterCount[idx] = {
            count:
              newFormArray[idx].NamePrefix.length +
              newFormArray[idx].FirstName.length +
              newFormArray[idx].LastName.length,
            type: newFormArray[idx].PassengerTypeCode,
          };
        }
      } else {
        nameCharacterCount[idx] = {
          count:
            newFormArray[idx].NamePrefix.length +
            newFormArray[idx].FirstName.length +
            newFormArray[idx].LastName.length,
          type: newFormArray[idx].PassengerTypeCode,
        };
      }

      // console.log("change name char count", nameCharacterCount);

      // let adultInfantCount;
      // if (isInfant) {
      //   // adultInfantCount = nameCharacterCount.filter(item => item.type === "ADT" || item.type === "INF")
      //   //     .map(item => {
      //   //       return {
      //   //          count: item.count
      //   //       }
      //   //     })
      //   adultInfantCount =
      //     appConstants.MAX_CHARACTER_COUNT_ADULT_INFANT -
      //     nameCharacterCount
      //       .filter(item => item.type === "ADT" || item.type === "INF")
      //       .reduce((acc, item) => {
      //         console.log(item);
      //         return acc + item.count;
      //       }, 0);
      // }
      // console.log({ adultInfantCount });
      this.setState({
        formArray: newFormArray,
        nameCharacterCount,
        // adultInfantCount
      });
    }
  };

  makePayment = () => {
    this.setState({
      errorMessage: false,
    });

    const {
      sessionId,
      passengerDetails,
      address1,
      city,
      country,
      address2,
      formArray,
      returnTicket,
      nameCharacterCount,
      phoneNumber,
      email,
    } = this.state;

    // let foundExcessCharacterCount = nameCharacterCount.find(item => item > 59);

    // Child Excess count which the max value should be 46
    let childExcessCount = nameCharacterCount
      .filter((item) => item.type === "CHD")
      .some((item) => item.count > appConstants.MAX_CHARACTER_COUNT_CHILD);
    // console.log({ childExcessCount });

    // check excess count for adult-infant
    let adultInfantExcessCount = nameCharacterCount
      .filter((item) => {
        return item.type === "ADT-INF";
      })
      .some(
        (item) => item.count > appConstants.MAX_CHARACTER_COUNT_ADULT_INFANT
      );
    // console.log({ adultInfantExcessCount });
    // let infantChildExcessCount = nameCharacterCount
    //   .filter(item => item.type === "INF" || item.type === "CHD")
    //   .some(item => item.count > appConstants.MAX_CHARACTER_COUNT_CHILD_INFANT);

    let adultExcessCount = nameCharacterCount
      .filter((item) => item.type === "ADT")
      .some((item) => item.count > appConstants.MAX_CHARACTER_COUNT_ADULT);

    // check for excess count and return appropriate error
    if (childExcessCount) {
      this.setState({
        errorMessage: `Please the child name should not exceed
         ${appConstants.MAX_CHARACTER_COUNT_CHILD} characters`,
      });
      return;
    }

    // check for excess count and return appropriate error
    if (adultExcessCount) {
      this.setState({
        errorMessage: `Please the adult name should not exceed
           ${appConstants.MAX_CHARACTER_COUNT_ADULT} characters`,
      });
      return;
    }

    if (adultInfantExcessCount) {
      this.setState({
        errorMessage: `Please both the adult name and child name should
        not exceed ${appConstants.MAX_CHARACTER_COUNT_ADULT_INFANT} characters`,
      });
      return;
    }
    let letters = /^[A-Za-z]+$/;
    let specialXterFilter = formArray.filter((item) => {
      let concatString = item.FirstName + item.LastName;
      return !concatString.match(letters);
    });

    if (specialXterFilter.length > 0) {
      this.setState({
        errorMessage:
          "Your first names and last names should only contain alphabets",
      });
      return;
    }

    // if (foundExcessCharacterCount) {
    //   this.setState({
    //     errorMessage: "Please your names should have a maximum of 59 characters"
    //   });
    //   return;
    // }
    // console.log({formArray});
    let modifiedFormArray = formArray.map((item) => {
      let obj = Object.assign({}, item);
      delete obj.sortKey;
      return obj;
    });
    // console.log({formArray});
    // console.log({modifiedFormArray});

    let payload = {
      RecommendationID: passengerDetails.Properties.RecommendationID,
      CombinationID: passengerDetails.Properties.CombinationID,
      SessionId: sessionId,
      AgentId: passengerDetails.AgentId,
      GDSId: passengerDetails.GDSId,
      BillingAddress: {
        ContactName: formArray[0].FirstName,
        AddressLine1: address1,
        AddressLine2: address2,
        City: city,
        CountryCode: country,
        ContactEmail: email,
        ContactMobileNo: phoneNumber,
      },
    };

    payload.AirTravellers = modifiedFormArray;
    payload.AirTravellers.forEach((item) => {
      item.Address = {
        ContactName: formArray[0].FirstName,
        AddressLine1: address1,
        AddressLine2: address2,
        City: city,
        CountryCode: country,
      };
    });

    // payload.AirTravellers.Address =  {
    //     ContactName: formArray[0].FirstName,
    //     AddressLine1: address1,
    //     AddressLine2: address2,
    //     City: city,
    //     CountryCode: country
    // }

    // console.log({payload});
    this.setState({ requesting: true });
    commonService.bookFlight(payload).then((res) => {
      let response = res.response;
      // console.log({response});
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        this.setState(
          { bookingDetails: response, loading: false, redirect: true },
          () => {
            this.props.history.push({
              pathname: "/booking-details",
              state: {
                bookingDetails: this.state.bookingDetails,
                returnTicket,
              },
            });
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState({ requesting: false });
        this.setState({
          errorMessage:
            typeof response === "string" ? response : response.Errors[0],
        });
        // console.log("error")
      }
    });
  };

  closeSnackbar = () => {
    this.setState({ errorMessage: false });
  };

  showPassengerType = (code) => {
    switch (code) {
      case "ADT":
        return "Adult";
      case "CHD":
        return "Child";
      case "INF":
        return "Infant";
      default:
        break;
    }
  };

  getPassengerTypeCharacterCount = (code) => {
    switch (code) {
      case "ADT":
        return appConstants.MAX_CHARACTER_COUNT_ADULT;
      case "CHD":
        return appConstants.MAX_CHARACTER_COUNT_CHILD;
      case "ADT-INF":
        return appConstants.MAX_CHARACTER_COUNT_ADULT_INFANT;
      default:
        return 0;
    }
  };

  render() {
    const {
      address1,
      city,
      country,
      address2,
      formArray,
      requesting,
      errorMessage,
      nameCharacterCount,
      email,
      phoneNumber,
      countries,
      // adultInfantCount,
      // isInfant
    } = this.state;
    const { classes } = this.props;
    return (
      <div className={"book-container"}>
        {requesting && <LinearProgress variant="query" />}
        {errorMessage && (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <CustomizedSnackbars
                className={classes.snackbar}
                message={errorMessage}
                variant={"info"}
                onClose={this.closeSnackbar}
              />
            </Grid>
          </Grid>
        )}

        <Paper className={`${classes.paper}`}>
          <p className={classes.info}>
            Please enter your personal information as it appears on your
            identification document
          </p>
          {formArray.map((item, key) => {
            // console.log({item})
            const doc = item.Documents;
            // console.log(doc)

            let parentKey = key;
            // console.log('parent key', parentKey)

            let passengerCode = item.PassengerTypeCode;

            return (
              <Grid container spacing={2} direction="row" key={key}>
                <Grid item md={12}>
                  <h3 className={classes.title}>
                    Passenger{" "}
                    <span style={{ fontSize: "14px", fontWeight: 400 }}>
                      {this.showPassengerType(item.PassengerTypeCode)}
                    </span>
                  </h3>
                </Grid>
                {/*<form>*/}

                <Grid style={{ textAlign: "end" }} item md={12}>
                  <small
                    style={{
                      color: `${
                        nameCharacterCount[key].count >
                        this.getPassengerTypeCharacterCount(
                          nameCharacterCount[key].type
                        )
                          ? "red"
                          : "green"
                      }`,
                    }}
                  >
                    {this.getPassengerTypeCharacterCount(
                      nameCharacterCount[key].type
                    ) - nameCharacterCount[key].count}{" "}
                    characters remaining
                  </small>
                </Grid>

                <Grid item md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">Title</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={item.NamePrefix}
                      onChange={this.handleChange(
                        key,
                        null,
                        null,
                        passengerCode
                      )}
                      name={"NamePrefix"}
                    >
                      {appConstants.namePrefixOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <TextField
                    required
                    id="standard-required"
                    label="First Name"
                    defaultValue="First Name"
                    name={"FirstName"}
                    className={classes.textField}
                    margin="normal"
                    value={item.FirstName}
                    onChange={this.handleChange(key, null, null, passengerCode)}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    required
                    id="standard-required"
                    label="Last Name"
                    defaultValue="Last Name"
                    name={"LastName"}
                    className={classes.textField}
                    margin="normal"
                    value={item.LastName}
                    onChange={this.handleChange(key, null, null, passengerCode)}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    required
                    id="standard-required"
                    label="Email"
                    defaultValue="Email"
                    name={"Email"}
                    className={classes.textField}
                    margin="normal"
                    value={item.Email}
                    onChange={this.handleChange(key, null, null, passengerCode)}
                  />
                </Grid>
                <Grid item md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={item.Gender}
                      onChange={this.handleChange(
                        key,
                        null,
                        null,
                        passengerCode
                      )}
                      name={"Gender"}
                    >
                      {appConstants.genderOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={4}>
                  <InlineDatePicker
                    label={"Date Of Birth"}
                    Date={item.BirthDate}
                    handleDateChange={(date) =>
                      this.handleDateChange(
                        date,
                        "BirthDate",
                        key,
                        null,
                        null,
                        passengerCode
                      )
                    }
                  />
                </Grid>

                {doc.map((item, key) => {
                  // console.log("doc item", item);
                  return (
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      key={key}
                      style={{ marginBottom: "2rem" }}
                    >
                      <Grid item md={12} style={{ marginBottom: 0 }}>
                        <h6 style={{ marginBottom: 0 }}>Document Details</h6>
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Document Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.DocType}
                            onChange={this.handleChange(key, doc, parentKey)}
                            name={"DocType"}
                          >
                            {appConstants.documentTypes.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          required
                          id="standard-required"
                          label="Document ID"
                          defaultValue="Document ID"
                          name={"DocID"}
                          className={classes.textField}
                          margin="normal"
                          value={item.DocID}
                          onChange={this.handleChange(key, doc, parentKey)}
                        />
                      </Grid>
                      {/* <Grid item md={4}>
                        <TextField
                          required
                          id="standard-required"
                          label="Birth Country"
                          defaultValue="Birth Country"
                          name={"BirthCountry"}
                          className={classes.textField}
                          margin="normal"
                          value={item.BirthCountry}
                          onChange={this.handleChange(key, doc, parentKey)}
                        />
                      </Grid> */}
                      <Grid item md={4} xs={12}>
                        {/* <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Birth Country
                          </InputLabel>
                          <AutoCompleteSelect
                            options={countries.map((option) => ({
                              value: option.CountryCode,
                              label: option.Name,
                            }))}
                            selectedOption={item.BirthCountry.value}
                            name={"BirthCountry"}
                            onChange={(e) =>
                              this.handleChange(key, doc, parentKey)
                            }
                            selectLabel={"Birth Country"}
                            placeholder={"Birth Country"}
                            isBottomBorderOnly
                          />
                        </FormControl> */}
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Birth Country
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.BirthCountry}
                            onChange={this.handleChange(key, doc, parentKey)}
                            name={"BirthCountry"}
                          >
                            {countries.map((option) => (
                              <MenuItem
                                key={option.CountryCode}
                                value={option.CountryCode}
                              >
                                {option.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Inner Document Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.InnerDocType}
                            onChange={this.handleChange(key, doc, parentKey)}
                            name={"InnerDocType"}
                          >
                            <MenuItem value={""} disabled>
                              Day
                            </MenuItem>
                            {appConstants.innerDocumentTypes.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        {/* <TextField
                          required
                          id="standard-required"
                          label="Document Issuance Country"
                          defaultValue="Document Issuance Country"
                          name={"IssueCountry"}
                          className={classes.textField}
                          margin="normal"
                          value={item.IssueCountry}
                          onChange={this.handleChange(key, doc, parentKey)}
                        /> */}
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">
                            Document Issuance Country
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={item.IssueCountry}
                            onChange={this.handleChange(key, doc, parentKey)}
                            name={"IssueCountry"}
                          >
                            {countries.map((option) => (
                              <MenuItem
                                key={option.CountryCode}
                                value={option.CountryCode}
                              >
                                {option.Name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <TextField
                          required
                          id="standard-required"
                          label="Document Issue Location"
                          defaultValue="Document Issue Location"
                          name={"DocIssueLocation"}
                          className={classes.textField}
                          margin="normal"
                          value={item.DocIssueLocation}
                          onChange={this.handleChange(key, doc, parentKey)}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InlineDatePicker
                          label={"Effective Date"}
                          Date={item.EffectiveDate}
                          handleDateChange={(date) =>
                            this.handleDateChange(
                              date,
                              "EffectiveDate",
                              key,
                              doc,
                              parentKey
                            )
                          }
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InlineDatePicker
                          label={"Expiry Date"}
                          Date={item.ExpiryDate}
                          handleDateChange={(date) =>
                            this.handleDateChange(
                              date,
                              "ExpiryDate",
                              key,
                              doc,
                              parentKey
                            )
                          }
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InlineDatePicker
                          label={"Expiration Date"}
                          Date={item.ExpireDate}
                          handleDateChange={(date) =>
                            this.handleDateChange(
                              date,
                              "ExpireDate",
                              key,
                              doc,
                              parentKey
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            );
          })}
          <Grid container spacing={2} direction="row">
            <Grid item md={12} style={{ marginBottom: 0 }}>
              <h6 style={{ marginBottom: 0, marginTop: "2rem" }}>
                Contact Information
              </h6>
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                id="standard-required"
                label="Address"
                defaultValue="Address"
                name={"address1"}
                className={classes.textField}
                margin="normal"
                value={address1}
                onChange={this.handleContactChange}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                label="Address 2"
                defaultValue="Address 2"
                name={"address2"}
                className={classes.textField}
                margin="normal"
                value={address2}
                onChange={this.handleContactChange}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                id="standard-required"
                label="City"
                defaultValue="City"
                name={"city"}
                className={classes.textField}
                margin="normal"
                value={city}
                onChange={this.handleContactChange}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                id="standard-required"
                label="Country"
                defaultValue="Country"
                name={"country"}
                className={classes.textField}
                margin="normal"
                value={country}
                onChange={this.handleContactChange}
              />

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={country}
                onChange={this.handleContactChange}
                name={"country"}
              >
                {countries.map((option) => (
                  <MenuItem key={option.CountryCode} value={option.CountryCode}>
                    {option.Name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                id="standard-required"
                label="Email"
                defaultValue="Email"
                name={"email"}
                className={classes.textField}
                margin="normal"
                value={email}
                onChange={this.handleContactChange}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                required
                id="standard-required"
                label="Phone Number"
                defaultValue="Phone number"
                name={"phoneNumber"}
                className={classes.textField}
                margin="normal"
                value={phoneNumber}
                onChange={this.handleContactChange}
              />
            </Grid>
            <Grid
              item
              xs
              direction="column"
              justify="right"
              alignItems="center"
              container
            >
              <Button
                variant="contained"
                size={"large"}
                color="primary"
                className={classes.button}
                onClick={this.makePayment}
              >
                PROCEED TO BOOK
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(BookFlight);
