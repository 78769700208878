import React, {Component} from 'react';
import {Grid, Paper, Button} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
// import {appConstants} from "../_constants";
// import InlineDatePicker from "../Components/inlineDatePicket";
import moment from "moment";
import CustomizedSnackbars from "../Components/Snackbar"
// import {commonService} from "../_services/common.service";
import { FaArrowLeft } from 'react-icons/fa';


const styles = theme => ({
    paper: {
        padding: theme.spacing(4),
        margin: 'auto',
        maxWidth: "50%",
        marginTop: "20px",
        marginBottom: "20px",
        // padding: "20px",
        border: "1px solid #fafafa"
    },
    formControl: {
        margin: theme.spacing(0),
        width: "100%",
    },
    title: {
        marginBottom: 0
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0),
        marginTop: 0,
        width: "100%",
    },
    snackbar: {
        position: "absolute",
        top: "5%",
        left: "77%",
        width: "300px"
    },
    buttonBack: {
        backgroundColor: 'rgba(41, 114, 231, .9)',
        color: '#fff',
        marginTop: "25px",
        textAlign: "left",
        marginLeft: "5px",
        '&:hover': {
            backgroundColor: 'rgba(41, 114, 231, .9)',
            color: '#fff',
        }
    },
});

class BookingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goingJourney: [],
            returnJourney: [],
            bookingDetails: {},
            travellers: [],
            returnTicket: false,
            errorMessage: false
        }
    }

    componentDidMount() {
        let pathState = this.props.history.location.state;
        // console.log({pathState})
        this.setState({bookingDetails: pathState.bookingDetails, returnTicket: pathState.returnTicket}, () => {
            let details = pathState.bookingDetails;
            // console.log({details})
            let travellers = pathState.bookingDetails.AirTravellers
            // console.log({travellers})
            this.setState({travellers});

            if (details.FlightSets.length > 0) {
                let goingJourney = details.FlightSets[0].FlightEntries[0];
                this.setState({goingJourney})

                if (this.state.returnTicket) {
                    let returnJourney = details.FlightSets[1].FlightEntries[0];
                    this.setState({returnJourney})

                }
            }
            if(details.Errors){
                this.setState({errorMessage: details.Errors[0]})
            }
        });

        // }


        // console.log({goingJourney})
        // console.log(goingJourney.FlightEntries)
        // console.log({returnJourney})
    }

    closeSnackbar = () => {
        this.setState({errorMessage: false})
    };

    backToFlight = () => {
        window.location.href = "/"
    };

    render () {
        const {classes} = this.props;
        const {bookingDetails, goingJourney, returnJourney, travellers, returnTicket,
            errorMessage
        } = this.state;
        // console.log({bookingDetails});
        // console.log({travellers});
        // console.log({goingJourney})
        // let departureJourney = bookingDetails.FlightSets;
    return (
        <div className={"book-container"}>
            <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center" className>
                <Grid item md={12}>
                    <Button size={"large"} color={"secondary"} className={`${classes.buttonBack}`} onClick={this.backToFlight}> <FaArrowLeft className={classes.arrowLeft}/>  Back to flight search</Button>
                </Grid>
            </Grid>
            {
                errorMessage &&
                <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <CustomizedSnackbars
                            className={classes.snackbar}
                            message={errorMessage}
                            variant={"info"}
                            onClose={this.closeSnackbar}
                        />
                    </Grid>
                </Grid>
            }
            <Paper className={`${classes.paper}`}>
                <Grid container spacing={2} direction="row" >
                    <Grid item md={12}>
                        <h4>Booking Reference No: {bookingDetails.BookingReferenceId} </h4>
                    </Grid>
                    {travellers.map((item, key) => {
                        return (
                            <Grid container spacing={2} direction="row" key={key}>
                                <Grid item md={12}>
                                    <h6 className={classes.title}>Passenger</h6>
                                </Grid>
                                <Grid item md={6}>
                                    <p>Name: {item.FirstName} {item.LastName}</p>
                                </Grid>
                                <Grid item md={6}>
                                    <p>Birth Date: {moment(item.BirthDate).format('YYYY-MM-DD')}</p>
                                    {item.Email !== null && <p>Email: {item.Email}</p>}
                                </Grid>
                            </Grid>
                        )
                    })}

                    <Grid item md={6}>
                        <h6>Departure Journey</h6>
                        <Grid item md={12}>
                            <p>Departure Airport Name: {goingJourney.DepartureAirportName}</p>
                            <p>Departure Date: {moment(goingJourney.DepartureDate).format("YYYY-MM-DD")}</p>
                        </Grid>
                    </Grid>
                    {
                        returnTicket &&
                        <Grid item md={6}>
                            <h6>Return Journey</h6>
                            <Grid item md={12}>
                                <p>Departure Airport Name: {returnJourney.DepartureAirportName}</p>
                                <p>Departure Date: {moment(returnJourney.DepartureDate).format("YYYY-MM-DD")}</p>
                            </Grid>
                        </Grid>
                    }


                </Grid>
            </Paper>
        </div>
    )
    }
}

export default withStyles(styles)(BookingDetails)
