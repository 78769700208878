import React, { Component } from "react";
import "./index.scss";
import {
  Grid,
  Button,
  MenuItem,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  LinearProgress,
} from "@material-ui/core";
import AutoCompleteSelect from "../Components/AutocompleteSelect";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "../Components/DatePicker";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import { commonService } from "../_services/common.service";
import { appConstants } from "../_constants";
// import {Redirect} from 'react-router';
import CustomizedSnackbars from "../Components/Snackbar";

const adultNo = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
];

const cabinClass = [
  {
    value: "Economy",
    label: "Economy",
  },
  {
    value: "Business Class",
    label: "Business Class",
  },
  {
    value: "First Class",
    label: "First Class",
  },
];

const childrenNo = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
];

const styles = (theme) => ({
  selectContainer: {
    backgroundColor: "rgba(41, 114, 231, .9)",
    // border: "1px solid #2972e7",
    width: "80%",
    padding: "32px",
    textAlign: "center",
    margin: "0 auto",
    marginTop: "5%",
    color: "#fff",
  },
  title: {
    color: "#fff",
  },
  textField: {
    width: "100%",
    color: "#fff",
  },
  input: {
    display: "flex",
    backgroundColor: "#fff",
    opacity: 1,
    height: "auto",
    width: "100%",
    // border: "1px solid rgba(255, 255, 255, 0.23)",
    borderRadius: "4px",
    marginTop: "15px",
  },
  paper: {
    width: "50%",
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    color: "#fff",
    marginTop: "25px",
  },
});

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: "rgba(0, 0, 0, 1)",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "rgba(0, 0, 0, 1)",
        },
        "&$outlined": {
          transform: "translate(14px, -6px) scale(1)",
        },
        "&$shrink": {
          // increase the specificity for the pseudo class
          transform: "translate(14px, -6px) scale(1)",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "rgba(255, 255, 255, 0.9)",
        position: "relative",
        "& $notchedOutline": {
          borderColor: " rgba(255, 255, 255, 1)",
          borderWidth: 2,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#4A90E2",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#4A90E2",
          borderWidth: 2,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#fff",
      },
    },
  },
  palette: {
    primary: {
      main: "#3eb006",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

// const greenTheme = createMuiTheme({ palette: { primary: green } })
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noOfAdults: 1,
      noOfChildren: 0,
      noOfInfants: 0,
      cabinValue: "Economy",
      departureDate: moment().format("YYYY-MM-DD"),
      returnDate: moment().format("YYYY-MM-DD"),
      departureLocations: [],
      destinationLocations: [],
      airlines: [],
      departureLocation: "",
      destinationLocation: "",
      airline: "",
      currency: "NGN",
      currencies: [],
      allFlights: [],
      returnValue: "2",
      requesting: false,
      redirect: false,
      returnTicket: true,
      orderbyPrice: "asc",
      sessionId: "",
      errorMessage: false,
      isAdmin: props.location.pathname === "/flight-search" ? true : false,
    };
  }

  componentDidMount() {
    console.log("testing 2");
    this.fetchAllAirports();
    this.fetchAllAirlines();
    this.fetchAllCurrencies();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleReactSelectChange = (value, name) => {
    return this.setState({ [name.name]: value ? value.value : "" });
  };

  handleDateChange = (date, target) => {
    this.setState({ [target]: date });

    if (target === "departureDate") {
      this.setState({ returnDate: date });
    }
  };

  fetchAllAirports = () => {
    commonService.getAllAirports().then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let result = response.Results;
        this.setState({
          departureLocations: result,
          destinationLocations: result,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        // console.log("error")
      }
    });
  };

  fetchAllAirlines = () => {
    commonService.getAllAirlines().then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let result = response.Results;
        this.setState({
          airlines: result,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
      }
    });
  };

  fetchAllCurrencies = () => {
    let payload = {
      PageSize: 1000,
      From: 0,
      Parameter: {
        CurrencyCode: "",
      },
    };
    commonService.getAllCurrencies(payload).then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let result = response.ResultList;
        this.setState({
          currencies: result,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
      }
    });
  };

  onSearch = () => {
    this.setState({ errorMessage: false });
    let {
      departureLocation,
      destinationLocation,
      airline,
      departureDate,
      returnDate,
      cabinValue,
      noOfAdults,
      noOfChildren,
      noOfInfants,
      returnValue,
      returnTicket,
      orderbyPrice,
      currency,
      isAdmin,
    } = this.state;

    let payload = {
      FlightAirlines: [
        {
          AirlineCode: airline,
          // Excluded: true,
        },
      ],
      FlightClasses: [
        {
          Name: cabinValue,
        },
      ],
      OrderbyFlightTime: true,
      OrderbyPrice: orderbyPrice,
      NumberOfRecommendation: 500,
      FlightPassengers: [],
      Currency: currency,
    };

    if (returnValue === "1") {
      payload.FlightRoutes = [
        {
          DepartureDate: moment(departureDate).format("YYYY-MM-DD HH:mm:ss"),
          OriginLocationCode: departureLocation,
          DestinationLocationCode: destinationLocation,
        },
      ];
    }
    if (returnValue === "2") {
      if (moment(returnDate).isBefore(departureDate)) {
        this.setState({
          errorMessage:
            "Your departure date should not be later than your return date",
        });
        return;
      }
      payload.FlightRoutes = [
        {
          DepartureDate: moment(departureDate).format("YYYY-MM-DD HH:mm:ss"),
          OriginLocationCode: departureLocation,
          DestinationLocationCode: destinationLocation,
        },
        {
          DepartureDate: moment(returnDate).format("YYYY-MM-DD HH:mm:ss"),
          OriginLocationCode: destinationLocation,
          DestinationLocationCode: departureLocation,
        },
      ];
    }

    if (noOfInfants > noOfAdults) {
      this.setState({
        errorMessage:
          "The number of infants should not be greater than the number of adults",
      });
      return;
    }
    if (noOfAdults > 0) {
      payload.FlightPassengers.push({
        Code: "ADT",
        Quantity: noOfAdults,
        DateOfBirth: "",
        Age: "",
      });
    }

    if (noOfChildren > 0) {
      payload.FlightPassengers.push({
        Code: "CHD",
        Quantity: noOfChildren,
        DateOfBirth: "",
        Age: "",
      });
    }

    if (noOfInfants > 0) {
      payload.FlightPassengers.push({
        Code: "INF",
        Quantity: noOfInfants,
        DateOfBirth: "",
        Age: "",
      });
    }

    this.setState({ requesting: true });

    commonService.searchFlightsAdmin(payload).then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let result = response.ResultList;
        let sessionId = response.SessionId;

        this.setState(
          {
            allFlights: result,
            requesting: false,
            redirect: true,
            sessionId,
          },
          () => {
            this.props.history.push({
              pathname: "/results",
              state: {
                allFlights: this.state.allFlights,
                returnTicket,
                sessionId,
                isAdmin,
              },
            });
          }
        );
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState({
          requesting: false,
          errorMessage: response && response.Errors && response.Errors[0],
        });
      }
    });
  };

  handleRadioChange = (event) => {
    this.setState({ returnValue: event.target.value }, () => {
      if (this.state.returnValue === "2") {
        this.setState({ returnTicket: true });
      } else if (this.state.returnValue === "1") {
        this.setState({ returnTicket: false });
      }
    });
  };

  closeSnackbar = () => {
    this.setState({ errorMessage: false });
  };

  render() {
    const {
      noOfAdults,
      noOfChildren,
      noOfInfants,
      cabinValue,
      departureDate,
      returnDate,
      returnValue,
      departureLocations,
      destinationLocations,
      airlines,
      departureLocation,
      destinationLocation,
      airline,
      currency,
      currencies,
      requesting,
      errorMessage,
      orderbyPrice,
    } = this.state;
    const { classes } = this.props;

    return (
      <div className={"filter-container"}>
        {requesting && <LinearProgress variant="query" />}
        {errorMessage && (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <CustomizedSnackbars
                className={classes.snackbar}
                message={errorMessage}
                variant={"info"}
                onClose={this.closeSnackbar}
              />
            </Grid>
          </Grid>
        )}

        <ThemeProvider theme={theme}>
          <Grid container spacing={2} className={classes.selectContainer}>
            <Grid item xs={12}>
              <h1 className={classes.title}>Book Your Flights</h1>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl component="fieldset" fullWidth>
                <RadioGroup
                  aria-label="position"
                  name="position"
                  value={returnValue}
                  onChange={this.handleRadioChange}
                  row
                >
                  <FormControlLabel
                    value={"2"}
                    control={<Radio style={{ color: "#fff" }} />}
                    label="Return"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value={"1"}
                    control={<Radio style={{ color: "#fff" }} />}
                    label="One Way"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid md={6} xs={12}>
              <RadioGroup
                aria-label="position"
                name="orderbyPrice"
                value={orderbyPrice}
                onChange={(e) =>
                  this.setState({ orderbyPrice: e.target.value })
                }
                row
              >
                <FormControlLabel
                  value={"asc"}
                  control={<Radio style={{ color: "#fff" }} />}
                  label="Ascending Price"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={"dsc"}
                  control={<Radio style={{ color: "#fff" }} />}
                  label="Descending Price"
                  labelPlacement="end"
                />
              </RadioGroup>
            </Grid>
            <Grid item md={3} xs={12}>
              <AutoCompleteSelect
                options={departureLocations.map((option) => ({
                  value: option.Code,
                  label: option.Name,
                }))}
                selectedOption={departureLocation.value}
                handleSelectChange={(e, name) =>
                  this.handleReactSelectChange(e, name)
                }
                selectLabel={"From"}
                name={"departureLocation"}
                placeholder={"FROM"}
                isClearable
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <AutoCompleteSelect
                options={destinationLocations.map((option) => ({
                  value: option.Code,
                  label: option.Name,
                }))}
                selectedOption={destinationLocation.value}
                handleSelectChange={(e, name) =>
                  this.handleReactSelectChange(e, name)
                }
                selectLabel={"To"}
                name={"destinationLocation"}
                placeholder={"TO"}
                isClearable
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <AutoCompleteSelect
                options={airlines.map((option) => ({
                  value: option.AirlineSisId,
                  label: option.Name,
                }))}
                selectedOption={airline.value}
                handleSelectChange={(e, name) =>
                  this.handleReactSelectChange(e, name)
                }
                selectLabel={"Airline"}
                name={"airline"}
                placeholder={"AIRLINE"}
                isClearable
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <AutoCompleteSelect
                defaultValue={{ label: "Nigerian Naira", value: currency }}
                options={currencies.map((option) => ({
                  value: option.CurrencyCode,
                  label: option.Name,
                }))}
                selectedOption={currency.value}
                handleSelectChange={(e, name) =>
                  this.handleReactSelectChange(e, name)
                }
                selectLabel={"Currency"}
                name={"currency"}
                placeholder={"CURRENCY"}
                isClearable
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <DatePicker
                label={"DEPARTURE"}
                Date={departureDate}
                handleDateChange={(date) =>
                  this.handleDateChange(date, "departureDate")
                }
                fullWidth
              />
            </Grid>
            {returnValue === "2" && (
              <Grid item md={3} xs={12}>
                <DatePicker
                  label={"RETURN"}
                  Date={returnDate}
                  handleDateChange={(date) =>
                    this.handleDateChange(date, "returnDate")
                  }
                  fullWidth
                />
              </Grid>
            )}
            <Grid item md={3} xs={12}>
              <TextField
                id="outlined-select-class"
                select
                label="Class"
                className={classes.textField}
                name={"cabinValue"}
                value={cabinValue}
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                // helperText="Please select Cabin class"
                margin="normal"
                variant="outlined"
                fullWidth
              >
                {cabinClass.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                id="outlined-select-currency"
                select
                label="Adults"
                className={classes.textField}
                value={noOfAdults}
                name={"noOfAdults"}
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                // helperText="Please select No of Adults"
                margin="normal"
                variant="outlined"
                fullWidth
              >
                {adultNo.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                id="select-numberOfChildren"
                select
                label="Children"
                className={classes.textField}
                value={noOfChildren}
                name={"noOfChildren"}
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                // helperText="Please select No of children"
                margin="normal"
                variant="outlined"
                fullWidth
              >
                {childrenNo.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={3} xs={12}>
              <TextField
                id="select-numberOFInfants"
                select
                label="Infants"
                className={classes.textField}
                value={noOfInfants}
                name={"noOfInfants"}
                onChange={this.handleChange}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                // helperText="Please select No of Infants"
                margin="normal"
                variant="outlined"
                fullWidth
              >
                {childrenNo.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item md={3} xs={12}>
              {/*<MuiThemeProvider theme={greenTheme}>*/}
              <Button
                variant="contained"
                size={"large"}
                color="primary"
                className={classes.button}
                onClick={this.onSearch}
                disabled={requesting}
              >
                Search
              </Button>
              {/*</MuiThemeProvider>*/}
            </Grid>
          </Grid>
        </ThemeProvider>
      </div>
    );
  }
}

export default withStyles(styles)(Filter);
