import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

const styles = () => ({
  grid: {
    width: "100%",
  },
  dateButton: {
    color: "#fff",
    borderColor: "#fff",
  },
  input: {
    // backgroundColor: "#fff",
    // border: "2px solid #fff"
  },
});

const today = new Date();
const maxDate = new Date().setDate(new Date().getDate() + 360);

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        // Name of the rule
        color: "rgba(0, 0, 0, 1)",
        "&$focused": {
          // increase the specificity for the pseudo class
          color: "rgba(0, 0, 0, 1)",
        },
        "&$outlined": {
          transform: "translate(14px, -6px) scale(1)",
        },
        "&$shrink": {
          // increase the specificity for the pseudo class
          transform: "translate(14px, -6px) scale(1)",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "rgba(255, 255, 255, 0.9)",
        position: "relative",
        "& $notchedOutline": {
          borderColor: " rgba(255, 255, 255, 1)",
          borderWidth: 2,
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#4A90E2",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#4A90E2",
          borderWidth: 2,
        },
      },
    },
  },
});
class InlineDatePicker extends React.Component {
  // state = {
  //     // The first commit of Material-UI
  //     selectedDate: new Date('2014-08-18T21:11:54'),
  // };

  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
  };

  render() {
    const { classes, Date, label, handleDateChange, ...rest } = this.props;
    // const { selectedDate } = this.state;

    // const muiTheme = theme =>({
    //     flatButton: {
    //         primaryTextColor: "#fff",
    //     }
    // })

    return (
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container className={classes.grid} justify="space-around">
            <DatePicker
              className={classes.dateButton}
              margin="normal"
              label={label ? label : "Date"}
              placeholder={"Date"}
              value={Date}
              minDate={today}
              maxDate={maxDate}
              onChange={handleDateChange}
              autoOk={true}
              variant={"outlined"}
              format="dd-MM-yyyy"
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              InputProps={{ className: classes.input }}
              InputLabelProps={{
                shrink: true,
              }}
              {...rest}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles, { withTheme: true })(InlineDatePicker);
