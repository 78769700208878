import React, { useState, useEffect } from "react";
import "./BookingWidget.css";

// const baseUrl = "https://tripswebwidget.test.vggdev.com/";
const baseUrl = "http://localhost:3001/";

const TRIPS_TOKEN =
  "631d095140d42fcee36f5e38e4f67b58f423136b00a15d37565d087617357a929c5dc29aa287d6487f5e7db59040c3d8a9fd76439f28277946435f86e0b2c5b3";

// const TRIPS_TOKEN =

//   "aa2112e13bfbc3b00269e6c88ae628fe385856b1877081371d24a1adbd3bffdc1b5f15ed7fd0bd343095d0420fbdfb03a07df786db8b23899f17e5a40b646900";

const APP_NAME = "BspTrips";

const MERCHANT = {
  FBN: {
    MerchantCode: "FBN",

    MerchantHash:
      "00e8a4af1168c1d31de1ffedfa36484c8c06c7f9db3164aa419757ed0adfdb55eb4949eeb2816585269578302ae33298f36116dcbfcbba86b3127397b89c5f43",
  },

  GTB: {
    MerchantCode: "GTB",

    MerchantHash: "",
  },

  WEMA: {
    MerchantCode: "WEMA",

    MerchantHash:
      "29043ccb43d0527b4739e8c6514c7d1026e90a2a091222005a3ad31515bc7271e612faac4ac119104f8b9bf51f68f16c0f746233957113ba5200cc5d8b647ad8",
  },
};

const { FBN, GTB, WEMA } = MERCHANT;
const url = `${baseUrl}?MerchantCode=${WEMA.MerchantCode}&MerchantHash=${WEMA.MerchantHash}&&AppName=${APP_NAME}&Token=${TRIPS_TOKEN}`;

const BookingWidget = () => {
  const [messageReceived, setMessageReceived] = useState("");
  const [response, setResponse] = useState();
  const [error, setError] = useState("");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);

  function messageCallBack(params) {
   
    // document.getElementById("refIframe").remove();
    setOpenIframe(false);
    
    //   inAppBrowserRef = undefined;
     
    if(params.data){
      console.log("params.data", params.data);

      setMessageReceived(JSON.stringify(params.data));
    }
  }

   

  const openTrips = async () => {
    //call backend here
    setOpenIframe(true);
    setLoadingStatus(true);
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${TRIPS_TOKEN}`);
    myHeaders.append("MerchantCode", MERCHANT.WEMA.MerchantCode);
    // myHeaders.append("MerchantCode", MERCHANT.WEMA.MerchantCode);
    const raw = { CustomerName: "Kelvin", CustomerIdentifier: "888" };
    const reqOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
    };
    const backendResp = await fetch(
      "https://bsptrips.staging.vggdev.com/api/account/gettoken",
      reqOptions
    );
    if (!backendResp.ok) {
      throw new Error(`HTTP error! status: ${backendResp.status}`);
      setLoadingStatus(false);
    }
    const data = await backendResp.json();
    console.log("backend response", data);
    setResponse(data);
    var target = "_blank";
    var options = "location=no,hidden=yes,footer=no";

    
    window.addEventListener("loadstart", loadStartCallBack);
    window.addEventListener("loadstop", loadStopCallBack);
    window.addEventListener("loaderror", loadErrorCallBack);
    window.addEventListener("exit", exitCallBack);
    window.addEventListener("message", messageCallBack);

    function loadStartCallBack() {
      setLoadingStatus(true);
      localStorage.clear();
    }
    function exitCallBack() {
      setLoadingStatus(false);
    }
    function loadStopCallBack() {
      if (window !== undefined) {
        window.iframe.show()
      }
    }
    function loadErrorCallBack(params) {
      setLoadingStatus(false);
      var scriptErrorMesssage =
        "alert('Sorry we cannot open that page. Message from the server is : " +
        params.message +
        "');";
      window.executeScript(
        { code: scriptErrorMesssage },
        executeScriptCallBack
      );
      window.close();
      window = undefined;
    }
    function executeScriptCallBack(params) {
      if (params[0] == null) {
        setError(
          "Sorry we couldn't open that page. Message from the server is : '" +
            params.message
        );
      }
    }
  };

  return (
    <div className="container">
      <h1>BookingWidget</h1>

      {messageReceived && (
        <div>
          <h5>Message from trips</h5>
          <p>{JSON.stringify(messageReceived, null, 2)}</p>
        </div>
      )}
      {error && <p>thers is error ........</p>}
      {/* {response && <p>{JSON.stringify(response, null, 2)}</p>} */}

      {!openIframe ? (
        <div className="btn-container">
          <button className="btn-booking" onClick={openTrips}>
            {" "}
            Book trips
          </button>
        </div>
      ) : (
        <div>
          <iframe
            title={"Booking widget"}
            id="refIframe"
            allowFullScreen
            frameBorder="0"
            height="800px"
            src={url}
            width="100%"
            scrolling="no"
          />
        </div>
      )}
    </div>
  );
};

export default BookingWidget;

// window.parent.postMessage({ message: "getAppData", value: MyDataToPass }, "*");

// From iframe
// componentDidMount() {
//     window.addEventListener("message", this.receiveMessage, false);
//     ...
//   }

// receiveMessage = (event: any) => {
//     const message = event.data.message;
// if( event.data.message === "getAppData" && event.data.value === 'done){
// setIsOpen(false)
//}

//     switch (message) {
//       case 'getAppData':
//         this.ToDoSomethingInVSCWebview(event.data.value);
//         break;
//     }
//     ...
//   }
