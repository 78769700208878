import { appHelpers } from "../_helpers";
import { appConstants } from "../_constants";
// import store from "./../store";

export const commonService = {
  getAllAirports,
  getAllAirlines,
  searchFlights,
  bookFlight,
  getAllCurrencies,
  getAllCountries,
  signOutSession,
  flightRules,
  searchFlightsAdmin,
};

//search available flights
function searchFlights(payload) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.BSP_TRIPS_URL}/api/Flight/SearchFlight`,
        payload,
        true
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

//search available flights (for admin user only)
function searchFlightsAdmin(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.BSP_TRIPS_URL}/api/Flight/searchFlightVerbose`,
      payload,
      true
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

//get avd airports
function getAllAirports() {
  return (
    appHelpers
      .getRequest(`${appConstants.AIRPORTS_URL}`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function getAllAirlines() {
  return appHelpers
    .getRequest(`${appConstants.PAS_URL}/api/airline/search`)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function getAllCurrencies(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.PAS_URL}/api/Flight/currenciesBSPTrips`,
      payload,
      true
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}


function getAllCountries(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.APP_URL}/api/flight/misc/countries`,
      payload,
      true
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}


function bookFlight(payload) {
  return (
    appHelpers
      .postRequest(
        `${appConstants.BSP_TRIPS_URL}/api/Flight/BookFlightOld`,
        payload,
        true
      )
      .then((res) => {
        return res;
      })
      .catch((error) => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        return appHelpers.formatPromiseResponse(
          errorMessage,
          appConstants.ERROR_RESPONSE
        );
      })
  );
}

function signOutSession(sessionId) {
  return appHelpers
    .postRequest(
      `${appConstants.BSP_TRIPS_URL}/api/flight/signout`,
      {},
      { SessionId: sessionId }
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}

function flightRules(payload) {
  return appHelpers
    .postRequest(
      `${appConstants.BSP_TRIPS_URL}/api/Flight/FlightRules`,
      payload,
      true
    )
    .then((res) => {
      return res;
    })
    .catch((error) => {
      let errorMessage = appHelpers.interpretErrorResponse(error);
      return appHelpers.formatPromiseResponse(
        errorMessage,
        appConstants.ERROR_RESPONSE
      );
    });
}
