import React from 'react';
import history from './_helpers/history'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Filter from './flight-booking/filter';
import './App.css';
import Layout from './Layout';
import BookingWidget from './booking-widget/BookingWidget';

function App() {
  return (
      <Router history={history}>
              <Switch>
                  <Route exact path={'/'} component={Filter}/>
                  <Route exact path={'/flight-search'} component={Filter}/> {/*this is the admin route*/}
                  <Route exact path={'/booking-widget'} component={BookingWidget}/> {/*this is the admin route*/}
                  <Route path='/' render={(props) => <Layout {...props} /> } />
              </Switch>
      </Router>
  );
}

export default App;
