import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import {FaTimes} from "react-icons/fa";
import Typography from "@material-ui/core/Typography";
// import { DialogActions } from "@material-ui/core";

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500]
    }
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="Close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <FaTimes />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2
    }
}))(MuiDialogContent);

export class ViewModal extends React.Component {
    state = {
        open: false
    };

    handleClickOpen = () => {
        this.setState({
            open: true
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    render() {
        const { onClose, open, title, children, size, dialogMessage } = this.props;
        // console.log({dialogMessage})
        return (
            <div>
                <Dialog
                    onClose={onClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    fullWidth={true}
                    maxWidth={size ? size : "sm"}
                >
                    <DialogTitle id="customized-dialog-title" onClose={onClose}>
                        {title}
                    </DialogTitle>
                    <DialogContent>{children}</DialogContent>

                    {dialogMessage && <h3 className="p-16 text-right">{dialogMessage}</h3>}
                </Dialog>
            </div>
        );
    }
}
