import React, { Component } from "react";
// import IdleTimer from "react-idle-timer";
import { appConstants } from "../_constants";
import { Switch, Route } from "react-router-dom";
import Results from "../flight-booking/results-card";
import Book from "../flight-booking/book";
import BookingDetails from "../flight-booking/booking-details";
import SessionTimeOutDialog from "../Components/SessionTimeOutDialog";
import { commonService } from "../_services/common.service";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeout: appConstants.SESSION_TIMEOUT_INTERVAL,
      showDialog: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    // this.idleTimer = null;
    // this.onAction = this._onAction.bind(this);
    // this.onActive = this._onActive.bind(this);
    // this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.loadSearchPage = this.loadSearchPage.bind(this);
  }

  componentDidMount() {
    setTimeout(this.signOutSession, appConstants.SESSION_TIMEOUT_INTERVAL);
  }

  // _onAction(e) {
  //   // console.log("user did something", e);
  //   // this.setState({ isTimedOut: false });
  // }

  // _onActive(e) {
  //   // console.log("user is active", e);
  //   this.setState({ isTimedOut: false });
  // }

  // _onIdle(e) {
  //   // console.log("user is idle", e);
  //   const isTimedOut = this.state.isTimedOut;
  //   if (isTimedOut) {
  //     this.signOutSession();
  //   } else {
  //     this.signOutSession();
  //     // this.idleTimer.reset();
  //     this.setState({ isTimedOut: true });
  //   }
  // }

  handleClose() {
    this.setState({ showDialog: false });
  }

  signOutSession = () => {
    let sessionId = this.props.history.location.state.sessionId;
    commonService.signOutSession(sessionId).then((res) => {
      if (res.response) {
        this.setState({ showDialog: true });
      }
    });
  };

  loadSearchPage() {
    let isAdmin = this.props.history.location.state.isAdmin;
    this.props.history.push(isAdmin ? "/flight-search" : "/");
  }

  render() {
    return (
      <div>
        {/* <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        /> */}

        <Switch>
          <Route exact path={"/results"} component={Results} />
          <Route exact path={"/bookFlight"} component={Book} />
          <Route exact path={"/booking-details"} component={BookingDetails} />
        </Switch>

        <SessionTimeOutDialog
          showDialog={this.state.showDialog}
          handleClose={this.handleClose}
          loadSearchPage={this.loadSearchPage}
        />
      </div>
    );
  }
}

export default Layout;
