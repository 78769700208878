export const appConstants = {
  // APP_USER_SESSION_EXPIRED: "avi-user-session-expired",
  // APP_USER_SESSION_ERROR: "avi-user-session-error",
  INACTIVITY_EXPIRATION_LIMIT: 3600,

  //Action Types
  ADD_LOG: "add_log",

  //API Status
  API_REQUEST_START: "API_REQUEST_START",
  API_REQUEST_FULL_PAGE_START: "API_REQUEST_FULL_PAGE_START",
  API_REQUEST_FINISH: "API_REQUEST_FINISH",
  REQUEST_SUCCESS: "REQUEST_SUCCESS",
  REQUEST_FAILURE: "REQUEST_FAILURE",
  GET_REQUEST: "get",
  POST_REQUEST: "post",

  START_REQUEST: "START_REQUEST",
  STOP_REQUEST: "STOP_REQUEST",

  GDS_SUCCESS: "GET_GDS_SUCCESS",
  AGENTS_SUCCESS: "GET_AGENTS_SUCCESS",
  MARKUPS_SUCCESS: "GET_MARKUPS_SUCCESS",
  MERCHANTS_SUCCESS: "GET_MERCHANTS_SUCCESS",
  MAKER_CHECKER_APP_ID: 8,

  MERCHANT_CODE:
    process.env.NODE_ENV === "development" ? "ACCESS" : window.env.merchantCode,
  MERCHANT_HASH:
  process.env.NODE_ENV === "development" ? "457b254021dcf67d33f9e0e567249487809d5ee5e9035daeffcab0beef4a27b644c089dd3d1e8f452c97c19148a8003eafb0c07114f33ee5258b72cecdfd5afd" : window.env.merchantHash,
  AUTHORIZATION_TOKEN:
    process.env.NODE_ENV === "development"
      ? "57ba5e98fd9e6d8503e31639a60b1b44eb2167fec34d4081ee81a1ba6e019fc313b2bb9cb16adf13a03a55fde3f0789c4eb600a0fcce5d7034ce8aa532e7d64a"
      // ? "194E1578816955B8DB4602383D32947C1E5ED1730F83BDF479D9829921139CA73B6765F3BE79CE107A2E7E8707B4D0AD502B9BD693E00FC7E070369FEB49096A"
      : window.env.authorizationToken,

  // URLS
  PAS_URL:
    process.env.NODE_ENV === "development"
      ? "https://passerelle.test.vggdev.com"
      : window.env.passerelleUrl,

  AIRPORTS_URL:
    process.env.NODE_ENV === "development"
      ? "https://avd.test.vggdev.com/api/airport/search"
      : window.env.airportsUrl,

  BSP_TRIPS_URL:
    process.env.NODE_ENV === "development"
      ? "https://bsptrips.test.vggdev.com"
      : window.env.bspTripsUrl,

  APP_URL: "https://bsptrips.test.vggdev.com",

  // RESPONSE TYPES
  SUCCESS_RESPONSE: 1,
  ERROR_RESPONSE: 2,

  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",

  // APP ID
  // APP_ID: "PSC Web",
  APP_ID: "BSP Trips",

  // USER ROLES
  ROLES: {
    AGENT_ADMIN: "Agent Admin",
    AGENT_USER: "Agent User",
    MERCHANT_ADMIN: "Merchant Admin",
    MERCHANT_USER: "Merchant User",
    VGG_ADMIN: "VGG Admin",
    VGG_USER: "VGG User",
  },
  APP_ENTITIES: {
    CREATE_MARKUP: "CreateMarkup",
    UPDATE_MARKUP: "UpdateMarkup",
    CREATE_AGENT: "Create Agent",
    UPDATE_AGENT: "Update Agent",
    ACTIVATE_AGENT: "Activate Agent",
    DEACTIVATE_AGENT: "Deactivate Agent",
    CREATE_MERCHANT: "Create Merchant",
    UPDATE_MERCHANT: "Update Merchant",
    ACTIVATE_MERCHANT: "Activate Merchant",
    DEACTIVATE_MERCHANT: "Deactivate Merchant",
    CREATE_GDS: "Create GDS",
    UPDATE_GDS: "Update GDS",
    ACTIVATE_GDS: "Activate GDS",
    DEACTIVATE_GDS: "Deactivate GDS",
    UPDATE_CREDENTIALS: "Update Agent Credentials",
  },
  MAKER_CHECKER_STATUS: {
    PENDING: 0,
    APPROVED: 1,
    REJECTED: 2,
  },

  GET_ENTITIES: "GET_ENTITIES",
  PAGE_SIZE: 1000,
  AIRPORTS_SUCCESS: "GET_AIRPORTS_SUCCESS",

  SESSION_TIMEOUT_INTERVAL: 1000 * 16 * 60,

  MAX_CHARACTER_COUNT_ADULT: 59,
  MAX_CHARACTER_COUNT_CHILD: 46,
  MAX_CHARACTER_COUNT_ADULT_INFANT: 41,

  namePrefixOptions: [
    {
      value: "Mr",
      label: "Mr",
    },
    {
      value: "Mrs",
      label: "Mrs",
    },
    {
      value: "Miss",
      label: "Miss",
    },
  ],

  genderOptions: [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ],
  documentTypes: [
    {
      value: "DOCO",
      label: "DOCO",
    },
    {
      value: "DOCS",
      label: "DOCS",
    },
    {
      value: "DOCA",
      label: "DOCA",
    },
  ],

  innerDocumentTypes: [
    {
      value: "Passport",
      label: "Passport",
    },
    {
      value: "Visa",
      label: "Visa",
    },
  ],

  months: [
    {
      value: "01",
      label: "January",
    },
    {
      value: "02",
      label: "February",
    },
    {
      value: "03",
      label: "March",
    },
    {
      value: "04",
      label: "April",
    },
    {
      value: "05",
      label: "May",
    },
    {
      value: "06",
      label: "June",
    },
    {
      value: "07",
      label: "July",
    },
    {
      value: "08",
      label: "August",
    },
    {
      value: "09",
      label: "September",
    },
    {
      value: "10",
      label: "October",
    },
    {
      value: "11",
      label: "November",
    },
    {
      value: "12",
      label: "December",
    },
  ],
};
