import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
// import ButtonBase from '@material-ui/core/ButtonBase';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa";
import { LinearProgress } from "@material-ui/core";
import { commonService } from "../_services/common.service";
import { appConstants } from "../_constants";
import { ViewModal } from "./viewModal";
import { appHelpers } from "../_helpers";
// import {Redirect} from "react-router";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: "70%",
    marginTop: "20px",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  currency: {
    margin: "0 auto",
    display: "block",
    color: "#3eb006",
  },
  divider: {
    width: "70%",
    border: "1px solid #eee",
  },
  time: {
    marginBottom: 0,
  },
  airportCode: {
    marginTop: 0,
    fontSize: "14px",
    color: "#777",
    textAlign: "right",
  },
  flightDuration: {
    marginBottom: 0,
    fontSize: "14px",
  },
  stop: {
    marginTop: 0,
    fontSize: "14px",
  },
  button: {
    backgroundColor: "#3eb006",
    color: "#fff",
  },
  buttonBack: {
    backgroundColor: "rgba(41, 114, 231, .9)",
    color: "#fff",
    marginTop: "25px",
    textAlign: "left",
    marginLeft: "5px",
  },
  arrowLeft: {
    marginRight: "10px",
  },
});

class CardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnTicket: false,
      allFlights: [],
      redirect: false,
      passengersDetails: [],
      sessionId: "",
      formArray: [],
      requesting: false,
      dialogOpen: false,
      fareRules: null,
      flightPenalties: null,
    };
  }

  componentDidMount() {
    let pathState = this.props.history.location.state;
    this.setState({
      allFlights: pathState.allFlights,
      returnTicket: pathState.returnTicket,
      sessionId: pathState.sessionId,
      isAdmin: pathState.isAdmin,
    });
  }

  backToFlight = () => {
    window.location.href = this.state.isAdmin ? "/flight-search" : "/";
  };

  book = (item) => {
    const { sessionId, returnTicket } = this.state;

    let mappedArray = item.PassengerFares.map((fare) => {
      return this.generatePassengerTemplate(fare);
    });

    // final value
    const passengerFormArray = [].concat(...mappedArray);

    if (passengerFormArray) {
      this.props.history.push({
        pathname: "/bookFlight",
        state: {
          passengersDetails: item,
          sessionId,
          formArray: passengerFormArray,
          returnTicket,
        },
      });
    }
  };

  generatePassengerTemplate = (fare) => {
    if (fare.Quantity > 1) {
      let passengerFormArray = [];
      for (let i = 0; i < fare.Quantity; i++) {
        let passengerFormObject = {
          PassengerTypeCode: fare.PassengerType,
          LastName: "",
          FirstName: "",
          BirthDate: "2019-11-21",
          NamePrefix: "",
          Email: "",
          Gender: "",
          Documents: [
            {
              DocType: "",
              DocIssueLocation: "",
              BirthCountry: "",
              DocID: "",
              IssueCountry: "",
              EffectiveDate: "",
              ExpiryDate: "",
              InnerDocType: "",
              ExpireDate: "2019-11-21",
            },
          ],
        };
        passengerFormArray.push(passengerFormObject);
      }
      return passengerFormArray;
    } else {
      let passengerFormObject = {
        PassengerTypeCode: fare.PassengerType,
        LastName: "",
        FirstName: "",
        BirthDate: "",
        NamePrefix: "",
        Email: "",
        Gender: "",
        Documents: [
          {
            DocType: "",
            DocIssueLocation: "",
            BirthCountry: "",
            DocID: "",
            IssueCountry: "",
            EffectiveDate: "",
            ExpiryDate: "",
            InnerDocType: "",
            ExpireDate: "",
          },
        ],
      };
      return [passengerFormObject];
    }
  };

  viewFareRules = (item) => {
    const { sessionId } = this.state;

    let payload = {
      RecommendationID: item.Properties.RecommendationID,
      CombinationID: item.Properties.CombinationID,
      PassengerTypeCode: "ADT",
      SessionId: sessionId,
      GdsId: item.GDSId,
      AgentId: item.AgentId,
    };
    this.setState({ requesting: true, dialogOpen: true });
    commonService.flightRules(payload).then((res) => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        // console.log({ response });
        this.setState({
          requesting: false,
          fareRules: res.response.FlightRules,
          flightPenalties: res.response.FlightRulePenalties,
        });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        this.setState({ requesting: false, errorMessage: response });
        // console.log("error")
      }
    });
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      fareRules: null,
      flightPenalties: null,
    });
  };
  render() {
    const {
      returnTicket,
      allFlights,
      requesting,
      dialogOpen,
      fareRules,
      flightPenalties,
      isAdmin,
    } = this.state;
    const { classes } = this.props;
    // console.log(sessionId,allFlights)
    return (
      <div className={`${classes.root} result-container`}>
        {requesting && <LinearProgress variant="query" />}
        {/*{redirect &&  <Redirect to={{*/}
        {/*pathname: '/bookFlight',*/}
        {/*state: {passengersDetails, sessionId, formArray, returnTicket}*/}
        {/*}}/>}*/}
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
          className
        >
          <Grid item md={12}>
            <Button
              size={"large"}
              color={"secondary"}
              className={`${classes.buttonBack}`}
              onClick={this.backToFlight}
            >
              <FaArrowLeft className={classes.arrowLeft} /> Back to flight
              search
            </Button>
          </Grid>
        </Grid>
        {allFlights.length === 0 && (
          <Paper className={`${classes.paper}`}>
            <Typography>No Record</Typography>
          </Paper>
        )}
        {allFlights.length > 0 &&
          allFlights.map((item) => {
            let toJourney = item.FlightDetails[0];
            let froJourney = item.FlightDetails[1];
            // console.log(item.FlightDetails);
            return (
              <Paper className={`${classes.paper}`}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item md={2}>
                    <h4>{toJourney.OperatingAirlineName}</h4>
                  </Grid>
                  <Grid item md={6} sm container>
                    <Grid
                      item
                      xs
                      direction="column"
                      justify="left"
                      alignItems="center"
                      container
                      spacing={2}
                    >
                      <h3 className={classes.time}>
                        {toJourney.DepartureDate &&
                          moment(toJourney.DepartureDate).format("HH:mm")}
                      </h3>
                      <p className={classes.airportCode}>
                        {toJourney.DepartureAirportCode}
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs
                      direction="column"
                      justify="left"
                      alignItems="center"
                      container
                      spacing={2}
                    >
                      <p className={classes.flightDuration}>
                        {toJourney.Duration}
                      </p>
                      <Divider className={classes.divider} />
                      {toJourney.StopOvers === 1 ? (
                        <p className={classes.stop}>1 stop</p>
                      ) : (
                        <p className={classes.stop}>
                          {toJourney.StopOvers} stops
                        </p>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs
                      direction="column"
                      justify="right"
                      alignItems="center"
                      container
                      spacing={2}
                    >
                      <h3 className={classes.time}>
                        {toJourney.ArrivalDate &&
                          moment(toJourney.ArrivalDate).format("HH:mm")}
                      </h3>
                      <p className={classes.airportCode}>
                        {toJourney.ArrivalAirportCode}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs
                    direction="column"
                    justify="right"
                    alignItems="center"
                    container
                    spacing={1}
                  >
                    <Button
                      variant={"contained"}
                      className={classes.button}
                      style={{ marginTop: "28px" }}
                      onClick={() => this.viewFareRules(item)}
                    >
                      View Fare Rules
                    </Button>
                  </Grid>
                  <Grid item md={2}>
                    <Typography
                      variant="subtitle1"
                      className={classes.currency}
                    >
                      {isAdmin
                        ? item.TotalFare &&
                          appHelpers.numberWithCommas(item.TotalFare)
                        : item.GdsTotalFare &&
                          appHelpers.numberWithCommas(item.GdsTotalFare)}{" "}
                      {item.Currency}
                    </Typography>
                    <Button
                      variant={"contained"}
                      className={classes.button}
                      // disabled
                      onClick={() => this.book(item)}
                    >
                      Book
                    </Button>
                  </Grid>
                </Grid>

                {returnTicket && (
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item md={2}>
                      <h4>{froJourney.OperatingAirlineName}</h4>
                    </Grid>
                    <Grid item md={6} sm container>
                      <Grid
                        item
                        xs
                        direction="column"
                        justify="left"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <h3 className={classes.time}>
                          {froJourney.DepartureDate &&
                            moment(froJourney.DepartureDate).format("HH:mm")}
                        </h3>
                        <p className={classes.airportCode}>
                          {froJourney.DepartureAirportCode}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs
                        direction="column"
                        justify="left"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <p className={classes.flightDuration}>
                          {froJourney.Duration}
                        </p>
                        <Divider className={classes.divider} />
                        {froJourney.StopOvers === 1 ? (
                          <p className={classes.stop}>1 stop</p>
                        ) : (
                          <p className={classes.stop}>
                            {froJourney.StopOvers} stops
                          </p>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs
                        direction="column"
                        justify="right"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <h3 className={classes.time}>
                          {froJourney.ArrivalDate &&
                            moment(froJourney.ArrivalDate).format("HH:mm")}
                        </h3>
                        <p className={classes.airportCode}>
                          {froJourney.ArrivalAirportCode}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs
                      direction="column"
                      justify="right"
                      alignItems="center"
                      container
                      spacing={2}
                    ></Grid>
                    <Grid item md={2}></Grid>
                  </Grid>
                )}

                {/* Admin area */}
                {isAdmin && (
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item md={2}>
                      <h4>Additional Info</h4>
                    </Grid>
                    <Grid item md={6} sm container>
                      <Grid
                        item
                        xs
                        direction="column"
                        justify="left"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <h3 className={classes.time}>Base Fare </h3>
                        <p className={classes.airportCode}>
                          {item.Currency}{" "}
                          {item.BaseFare &&
                            appHelpers.numberWithCommas(item.BaseFare)}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs
                        direction="column"
                        justify="left"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <h3 className={classes.time}>GDS Total Fare </h3>
                        <p className={classes.airportCode}>
                          {item.Currency}{" "}
                          {item.GdsTotalFare &&
                            appHelpers.numberWithCommas(item.GdsTotalFare)}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs
                        direction="column"
                        justify="right"
                        alignItems="center"
                        container
                        spacing={2}
                      >
                        <h3 className={classes.time}>Taxes </h3>
                        <p className={classes.airportCode}>
                          {item.Taxes && (
                            <>
                              {item.Currency}{" "}
                              {appHelpers.numberWithCommas(item.Taxes)}
                            </>
                          )}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs
                      direction="column"
                      justify="right"
                      alignItems="center"
                      container
                      spacing={1}
                    >
                      <h3 className={classes.time}>Service Charge </h3>
                      <p className={classes.airportCode}>
                        {item.Currency}{" "}
                        {item.TotalServiceCharge &&
                          appHelpers.numberWithCommas(item.TotalServiceCharge)}
                      </p>
                    </Grid>
                    <Grid item md={2}></Grid>
                  </Grid>
                )}
              </Paper>
            );
          })}
        <ViewModal
          onClose={this.handleDialogClose}
          open={dialogOpen}
          title={`Fare Rules & Penalties`}
          size={"md"}
        >
          {!requesting && (
            <div>
              <h2>Fare Rules</h2>
              {fareRules &&
                fareRules.length > 0 &&
                fareRules.map((item, index) => {
                  return <p key={index}>{item.Text}</p>;
                })}
              {(!fareRules || fareRules.length === 0) && (
                <h4>No fare rule found</h4>
              )}
              <br />
              <h2>Flight Penalties</h2>
              {flightPenalties &&
                flightPenalties.length > 0 &&
                flightPenalties.map((item, index) => {
                  return item.FareRules.map((rule, index) => {
                    return (
                      <p style={{ whiteSpace: "pre-wrap" }} key={index}>
                        {rule.Text}
                      </p>
                    );
                  });
                })}
              {(!flightPenalties || flightPenalties.length === 0) && (
                <h4>No flight penalty found</h4>
              )}
            </div>
          )}
        </ViewModal>
      </div>
    );
  }
}

export default withStyles(styles)(CardComponent);
